import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import paths from './paths';
import store from '@/store/index';

Vue.use(VueRouter);

const Router = new VueRouter({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths,
});

Router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.path !== '/account/login') {
      if (
        localStorage.auth_token !== undefined ||
        localStorage.auth_token !== null
      ) {
        next();
      } else {
        store.dispatch('resetSnackBarState', null, { root: true });
        next('account.login');
      }
    } else {
      next();
    }
  }
  if (to.name) {
    NProgress.start();
    NProgress.inc(0.0);
    next();
  }
});

Router.afterEach(() => {
  // Complete animation of the router progress bar
  NProgress.done();
});

export default Router;
